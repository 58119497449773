<template>
<div class="goentry" :class="{'is-show': isShow}">
	<a data-fancybox data-src="#entry-content">
        <img src="/assets/img/frontpage/fixed_entry_btn_off.webp" alt="応募する_off" width="193" height="258">
        <img src="/assets/img/frontpage/fixed_entry_btn_on.webp" alt="応募する_on" width="193" height="258">
    </a>
</div>
</template>

<script>
export default {
    data() {
        return {
            isShow: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', () => {
            if (window.scrollY < 1200) {
                this.isShow = false;
            } else {
                this.isShow = true;
            }
        });
    },
}
</script>