<script>
// wow.js
const WOW = require('wow.js');
import 'animate.css';

// swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';

// gsap
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// Fancybox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

// sticky-sidebar
import StickySidebar from "sticky-sidebar";

// vue components
import gotop from './components/Gotop.vue';
import goentry from './components/Goentry.vue';
import aco from './components/Aco.vue';

// Chart.js
import Chart from 'chart.js/auto';

export default {
    inject: ["mq"],

    components: {
        gotop,
        aco,
        Swiper,
        SwiperSlide,
        gsap,
        ScrollTrigger,
        Fancybox,
        goentry,
        // Chart,
    },

    data() {
        return {
            headerH: 0, //ヘッダーの高さ
            gnavActive: false, // グロナビアクティブ管理(SP)
            gnavScroll: false, //グロナビスクロール管理（SP）
            transform: { //グロナビとNEWSのアクティブ管理(PC)
                gnav: {},
                topNews: {},
            },
            sticky: null, //sticky-sidebarが実行されたら値が入る

            // Swiperオプション
            swiperOptions: {
                // トップページ MV
                topMv: {
                    modules: [Autoplay, EffectFade],
                    slidesPerView: 1,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                    speed: 1500,
                    loop: true,
                    effect: 'fade'
                },
                // トップページ　社員インタビュー
                topInterview: {
                    modules: [Autoplay],
                    slidesPerView: 'auto',
                    autoplay: {
                        delay: 5000,
                    },
                    spaceBetween: 15,
                    speed: 1000,
                    loop: true,
                    breakpoints: {
                        768: {
                            spaceBetween: 60,
                        }
                    }
                },
                // トップページ　介護スタッフの1日
                topFlow: {
                    modules: [Autoplay],
                    slidesPerView: 'auto',
                    loop: true,
                    speed: 15000,
                    allowTouchMove: false, //スワイプ無効
                    autoplay: {
                        delay: 0, // 途切れなくループ
                    },
                }
            }
        }
    },

    methods: {
        // wow.js初期化
        wowInit() {
            // SP/PC判定
            let offset;
            if (this.mq.mdMinus) {
                offset = 100; //SP
            } else {
                offset = 300; //PC
            }

            // 初期化
            const wow = new WOW({
                offset: offset,
                animateClass: 'animate__animated'
            });
            wow.init();
        },

        // ヘッダーの高さを取得
        getHeaderH() {
            this.headerH = this.$refs.header.getBoundingClientRect().height;
        },

        // グロナビ開閉（SP）
        toggleGnav() {
            if (this.mq.current === 'xl') return;
            this.gnavActive = !this.gnavActive;

            // グロナビがウィンドウの高さより大きければスクロールクラスを付与
            const gnavH = this.$refs.gnavList.getBoundingClientRect().height;
            if (this.gnavActive && window.innerHeight < gnavH) this.gnavScroll = true;
        },

        // グロナビ・NEWS開く (PC)
        showTransformEl(el) {
            if (this.mq.current != 'xl') return;

            const name = el.__vnode.ref.r; //refの名前を取得
            const conts = el.querySelector('.js-conts');
            const contsRect = conts.getBoundingClientRect(); //コンテンツのwidth/height

            this.transform[name].isActive = true; //アクティブクラス付与
            this.transform[name].isHamburger = false; //ハンバーガークラス削除
            el.style.width = `${contsRect.width}px`; //コンテンツのサイズに変形
            el.style.height = `${contsRect.height}px`;
        },

        // グロナビ・NEWS閉じる (PC)
        hideTransformEl(el) {
            if (this.mq.current != 'xl') return;

            const name = el.__vnode.ref.r; //refの名前を取得
            const initialRect = this.transform[name].initialRect; //非アクティブ状態(初期描画時)のwidth/height

            this.transform[name].isActive = false; //アクティブクラス削除
            this.transform[name].isHamburger = true; //ハンバーガークラス付与
            if (this.transform[name].isFv) this.transform[name].isFvClose = true; //ファーストビューで閉じた場合フラグをON
            el.style.width = `${initialRect.width}px`; //初期描画時のサイズに変形
            el.style.height = `${initialRect.height}px`;
        },

        // グロナビ・NEWSの初期設定（PC）
        initTransformEl() {
            const vm = this;

            // ターゲット要素を取得
            const targetEl = [
                vm.$refs.gnav,
                vm.$refs.topNews,
            ];

            // スクロールで切り替わるポイント
            const posMap = {
                gnav: window.innerHeight,
                topNews: 200,
            };

            for (let i = 0, len = targetEl.length; i < len; ++i) {
                const el = targetEl[i]; //要素を取得
                if (!el) continue;

                // 一旦スタイル属性とアクティブ管理をリセット
                el.removeAttribute('style');
                this.gnavActive = false;
                this.gnavScroll = false;
                
                if (this.mq.current != 'xl') continue; //SPの場合終了

                const name = el.__vnode.ref.r; //refの名前を取得
                const pos = posMap[name]; //切り替わるポイント

                // 初期値の設定
                vm.transform[name] = {
                    isActive: false, //アクティブ状態ならtrue
                    isHamburger: false, //アクティブ状態から閉じたらtrue
                    initialRect: el.getBoundingClientRect(), //非アクティブ状態（初期描画時）のwidth/height
                    isFv: false, //posより上ならtrue
                    isFvClose: false, //posより上で閉じたらtrue
                }

                // スクロールがposより上なら初期描画時に開く
                if (window.scrollY < pos) {
                    vm.showTransformEl(el); //グロナビを開く
                    vm.transform[name].isFv = true; //ファーストビュークラスを付与
                }

                // スクロールイベント
                window.addEventListener('scroll', () => {
                    // posより下
                    if (window.scrollY > pos) {
                        if (vm.transform[name].isActive) this.hideTransformEl(el); //グロナビ閉じる
                        vm.transform[name].isFv = false; //ファーストビュークラスを削除
                        vm.transform[name].isFvClose = false; //ファーストビューで閉じたかどうかのフラグをリセット
                    // posより上
                    } else {
                        if (!vm.transform[name].isActive && !vm.transform[name].isFvClose) this.showTransformEl(el); //グロナビ開く
                        vm.transform[name].isFv = true; //ファーストビュークラスを付与
                    }
                });
            }
        },

        // sticky要素　初期設定
        initSticky() {
            if (this.mq.mdMinus || !document.querySelector('.js-sticky')) return;

            // 実行してなければ初期化
            if (!this.sticky) {
                this.sticky = new StickySidebar('.js-sticky', {
                    containerSelector: '.js-sticky-container',
                    innerWrapperSelector: 'js-sticky-inner',
                    topSpacing: this.headerH,
                    bottomSpacing: 0
                });
            
            // 実行済であれば更新
            } else {
                this.sticky.updateSticky();
                // console.log('updated');
            }
        },

        // load時に実行
        onLoad() {
            this.getHeaderH();
            this.initSticky();
        },

        // メディアクエリが変わった時に実行
        onMqChange() {
            this.getHeaderH();
            this.initTransformEl();
            this.initSticky();
        },

        //フェードインアップアニメーション
        gsapFadeInUp() {
            const groups = gsap.utils.toArray('.gsap-fadeInUp');
            
            if (groups) {
                groups.forEach((group) => {

                    //個別で設定したいオプション
                    var options = {
                        delay: 0, //スタートの遅延
                    }

                    if(group.dataset.delay){
                        options.delay = group.dataset.delay;
                    }

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: group,
                            start: 'top bottom-=200',
                        }
                    });

                    tl.from(group, {
                        opacity: 0,
                        y: 100,
                        ease: "power2.Out",
                        delay: options.delay,
                    });
                });
            }
        },

        //連続フェードインアップ
        gsapSeriesFadeInUp(){
            const groups = gsap.utils.toArray('.gsap-seriesFadeInUp-trigger');

            if (groups) {
                groups.forEach((group) => {
                    const trigger = group;
                    const boxes = gsap.utils.toArray(group.querySelectorAll('.gsap-seriesFadeInUp'));

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: trigger,
                            start: 'top bottom-=200',
                        }
                    });

                    tl.from(boxes, {
                        opacity: 0,
                        ease: "power2.Out",
                        stagger: {
                        each: 0.4,
                        },
                    });

                });
            }
        },

        //カウントアップアニメーション
        gsapcountUp(){
            const groups = gsap.utils.toArray('.box__number'); //発火対象

            if(groups){
                groups.forEach((group) => {
                    const countUpTrigger = group.querySelector('.count'); //発火タイミング
                    const countUpTarget = group.querySelector('.count'); //アニメーション対象
                    var elementDic = {
                        from:countUpTrigger.dataset.from,
                        to:countUpTarget.dataset.to
                    }
                    var elementNum = {count:elementDic.from};

                    gsap.to(elementNum,{
                        count:elementDic.to,//カウントさせる数値(終着点)
                        duration:2,//カウントアップさせる秒数
                        ease:'none',//デフォルトでpower(指数)がかかっている
                        scrollTrigger:{
                            trigger:countUpTrigger,// トリガーとなる要素の指定
                            start:'top bottom',// トリガー位置の調整
                            markers:false,// マーカーの表示
                        },
                        onUpdate:()=>{
                            if(this.isInteger(elementDic.to)){
                                //整数バージョン
                                countUpTarget.textContent = parseFloat(elementNum.count).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });// 数字が変わる度にDOM要素に代入
                            }else{
                                //小数点バージョン
                                countUpTarget.textContent = parseFloat(elementNum.count).toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });// 数字が変わる度にDOM要素に代入
                            }
                        }
                    })
                });
            }
        },

        //円グラフ
        gsapDoughnut(){
            const ctx = document.getElementById('myChart');
            if(ctx){
                const ctxArgs = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                        data: [82.9,17.1],
                        backgroundColor: ['#B1CCEA', '#D6D8DB'],
                        borderWidth: 4,
                        }]
                    },
                    options: {
                        hover: {
                            mode: null  // ホバー時の挙動を無効化する
                        },
                        plugins:{
                            tooltip: {
                                enabled: false // ツールチップを無効化する
                            },
                        },
                        cutout: 80,
                    }
                }
                gsap.to(ctx, {
                    scrollTrigger: {
                    trigger: ctx,
                    start: 'top 80%',
                    onEnter: function() {
                        new Chart(ctx, ctxArgs);
                    },
                    markers:false,
                    }
                });
            }
        },

        //棒グラフ
        gsapBar(){
            const bars = gsap.utils.toArray('.childcare__bar');

            if(bars){
                bars.forEach((bar) => {
                    const trigger = bar.querySelector('.childcare__bar-item');
                    const boxes = gsap.utils.toArray(bar.querySelectorAll('.childcare__bar-graph'));

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: trigger,
                            start: 'top bottom-=200',
                        }
                    });

                    for (var i = 0; i < boxes.length; i++) {
                        if(i == 0){
                            tl.from(boxes[i], { height: 0, duration: 1.5 },);
                        }else{
                            tl.from(boxes[i], { height: 0, duration: 1.5 },"-=1.0");
                        }
                    }

                    tl.from('.childcare__bar-arrow', { 
                            opacity: 0,
                            y: 50,
                            ease: "power2.Out", 
                        },"-=0.5"
                    );

                });
            }
        },

        //TOP：MVアニメーション
        gsapTop(){
            const targetElement = document.querySelector('.mv');

            if (targetElement) {
                const boxes = Array.from(document.querySelectorAll('.mv .inner .js-move'));
                const main = document.querySelector('.mv__main');

                const tl = gsap.timeline();
                tl.from(main, { y: -100, opacity: 0, duration: 1, delay: 0.5 },);

                boxes.forEach((box, index) => {
                    var i = Math.floor(Math.random() * 100) + 1;
                    var moveY = 0;
                    var moveX = 0;
                    
                    if( index >= 3 ){
                        moveY = -100 - (i*1);
                        moveX = 100 + (i*1);
                        tl.from(box, { y: moveY, x: moveX, opacity: 0, duration: 1 }, `-=0.4`);
                    }else{
                        moveY = -100 - (i*1);
                        moveX = -100 - (i*1);
                        tl.from(box, { y: moveY, x: moveX, opacity: 0, duration: 1 }, `-=0.4`);
                    }
                    // console.log('x:'+ moveX);
                });
            }
        },

        //整数かどうか判定
        isInteger(value) {
            return value % 1 === 0;
        }
    },

    mounted() {
        this.wowInit();
        this.initTransformEl();
        window.addEventListener('load', this.onLoad);
        
        this.gsapFadeInUp();
        this.gsapSeriesFadeInUp();
        this.gsapTop();
        this.gsapcountUp();
        this.gsapDoughnut();
        this.gsapBar();

        Fancybox.bind('[data-fancybox]', {
            // Custom options for all galleries
        });
    },

    watch: {
        // メディアクエリが変わったとき
        'mq.current'() {
            this.onMqChange();
        },
    }
}
</script>